@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght,YTLC@0,6..12,75..125,200..1000,440..540;1,6..12,75..125,200..1000,440..540&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.heroBg {
  background: rgb(26, 26, 27);
  background: linear-gradient(
    51deg,
    rgba(26, 26, 27, 1) 0%,
    rgba(27, 27, 34, 1) 13%,
    rgba(33, 31, 45, 1) 29%,
    rgba(37, 34, 50, 1) 40%,
    rgba(43, 35, 31, 1) 68%,
    rgba(39, 31, 34, 1) 74%,
    rgba(41, 34, 30, 1) 84%,
    rgba(29, 27, 28, 1) 93%,
    rgba(26, 26, 27, 1) 100%
  );
}
/* Fonts */
/* nunito sans */
.boldNunito {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "width" 75 /* Use a value from 75 to 125  */ "YTLC"
    440 /* Use a value from 440 to 540 */;
}

/* Poppins */
.poppinsThin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppinsExtralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppinsLight {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppinsRegular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppinsMedium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppinsSemibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppinsBold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppinsExtrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppinsBlack {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppinsThinItalic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppinsExtralightItalic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppinsLightItalic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppinsRegularItalic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #5acbc9;
  transform-origin: 0%;
}

.customClass {
  width: calc(100vw - 250px);
}
